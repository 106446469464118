var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { "margin-top": "15px" } },
    [
      _c("a-input-search", {
        staticStyle: { "margin-bottom": "8px", width: "90%" },
        attrs: { placeholder: "Search" },
        on: { change: _vm.filterNode },
      }),
      _vm.deptOptions && _vm.deptOptions.length
        ? _c("a-tree", {
            attrs: {
              "expanded-keys": _vm.expandedKeys,
              "auto-expand-parent": _vm.autoExpandParent,
              "tree-data": _vm.deptOptions,
              replaceFields: _vm.replaceFields,
            },
            on: { select: _vm.handleNodeClick, expand: _vm.onExpand },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }