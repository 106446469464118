<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <!-- 条件搜索 -->
      <a-row>
        <a-col :span="4" style="border-right: 1px solid #e8e8e8">
          <!-- 机构树 -->
          <dept-tree ref="deptTree" :deptOptions="deptOptions" @select="clickDeptNode" />
        </a-col>
        <a-col :span="20" style="padding-left: 10px">
          <div class="table-page-search-wrapper">
            <a-form layout="inline">
              <a-row :gutter="48">
                <a-col :md="8" :sm="24">
                  <a-form-item label="设备编号" prop="deviceId">
                    <a-input v-model="queryParam.deviceId" placeholder="请输入设备编号" allow-clear />
                  </a-form-item>
                </a-col>
                <a-col :md="8" :sm="24">
                  <a-form-item label="Sim卡号" prop="simNo">
                    <a-input v-model="queryParam.simNo" placeholder="请输入卡号" allow-clear />
                  </a-form-item>
                </a-col>
                <a-col :md="8" :sm="24">
                  <a-form-item label="是否虚拟设备" prop="virtualDevice">
                    <a-select allowClear placeholder="请选择是否虚拟设备" v-model="queryParam.virtualDevice">
                      <a-select-option v-for="d in virtualDeviceTypeOptions" :key="d.dictValue" :value="d.dictValue">{{
                        d.dictLabel
                      }}</a-select-option>
                    </a-select>
                  </a-form-item>
                </a-col>
                <a-col :md="8" :sm="24">
                  <a-form-item label="设备类型" prop="deviceType">
                    <a-select allowClear placeholder="请选择设备类型" v-model="queryParam.deviceType">
                      <a-select-option v-for="d in deviceTypeOptions" :key="d.dictValue" :value="d.dictValue">{{
                        d.dictLabel
                      }}</a-select-option>
                    </a-select>
                  </a-form-item>
                </a-col>

                <template v-if="advanced">
                  <a-col :md="8" :sm="24">
                    <a-form-item label="IMEI号码" prop="imei">
                      <a-input v-model="queryParam.imei" placeholder="请输入IMEI号码" allow-clear />
                    </a-form-item>
                  </a-col>
                  <a-col :md="8" :sm="24">
                    <a-form-item label="设备型号" prop="deviceModel">
                      <a-input v-model="queryParam.deviceModel" placeholder="请输入设备型号" allow-clear />
                    </a-form-item>
                  </a-col>
                  <a-col :md="8" :sm="24">
                    <a-form-item label="最近注册时间">
                      <a-range-picker
                        style="width: 100%"
                        v-model="daterangeRegisterTime"
                        valueFormat="YYYY-MM-DD"
                        format="YYYY-MM-DD"
                        allow-clear
                      />
                    </a-form-item>
                  </a-col>
                  <a-col :md="8" :sm="24">
                    <a-form-item label="安装时间(首次注册时间)">
                      <a-range-picker
                        style="width: 100%"
                        v-model="daterangeInstallTime"
                        valueFormat="YYYY-MM-DD"
                        format="YYYY-MM-DD"
                        allow-clear
                      />
                    </a-form-item>
                  </a-col>
                  <a-col :md="8" :sm="24">
                    <a-form-model-item label="激活状态" prop="activation">
                      <a-select placeholder="请选择激活状态" v-model="queryParam.activation">
                        <a-select-option
                          :value="item.dictValue"
                          v-for="item in activationOptions"
                          :key="item.dictValue"
                        >
                          {{ item.dictLabel }}
                        </a-select-option>
                      </a-select>
                    </a-form-model-item>
                  </a-col>
                  <a-col :md="8" :sm="24">
                    <a-form-item label="删除标志" prop="stateFlag">
                      <a-input v-model="queryParam.stateFlag" placeholder="请输入删除标志" allow-clear />
                    </a-form-item>
                  </a-col>
                </template>
                <a-col :md="(!advanced && 8) || 24" :sm="24">
                  <span
                    class="table-page-search-submitButtons"
                    :style="(advanced && { float: 'right', overflow: 'hidden' }) || {}"
                  >
                    <a-button type="primary" @click="handleQuery"><a-icon type="search" />查询</a-button>
                    <a-button style="margin-left: 8px" @click="resetQuery"><a-icon type="redo" />重置</a-button>
                    <a @click="toggleAdvanced" style="margin-left: 8px">
                      {{ advanced ? '收起' : '展开' }}
                      <a-icon :type="advanced ? 'up' : 'down'" />
                    </a>
                  </span>
                </a-col>
              </a-row>
            </a-form>
          </div>
          <!-- 操作 -->
          <a-toolbar :loading="loading" refresh @refreshQuery="getList" tid="1">
            <div class="table-operations">
              <a-button
                type="primary"
                @click="$refs.createForm.handleAdd()"
                v-hasPermi="['tenant:jt808:deviceInfo:add']"
              >
                <a-icon type="plus" />新增
              </a-button>
              <a-button
                type="primary"
                :disabled="single"
                @click="$refs.createForm.handleUpdate(undefined, ids)"
                v-hasPermi="['tenant:jt808:deviceInfo:edit']"
              >
                <a-icon type="edit" />修改
              </a-button>
              <a-button
                type="danger"
                :disabled="multiple"
                @click="handleDelete"
                v-hasPermi="['tenant:jt808:deviceInfo:remove']"
              >
                <a-icon type="delete" />删除
              </a-button>
              <a-button
                type="dashed"
                @click="$refs.importExcel.importExcelHandleOpen()"
                v-hasPermi="['tenant:jt808:deviceInfo:import']"
              >
                <a-icon type="import" />导入
              </a-button>
              <a-button type="primary" @click="handleExport" v-hasPermi="['tenant:jt808:deviceInfo:export']">
                <a-icon type="download" />导出
              </a-button>
              <!-- <a-button
          type="dashed"
          shape="circle"
          :loading="loading"
          :style="{ float: 'right' }"
          icon="reload"
          @click="getList"
        /> -->
            </div>
          </a-toolbar>
          <!-- 增加修改 -->
          <create-form
            ref="createForm"
            @ok="getList"
            :deptOptions="deptOptions"
            :statusOptions="statusOptions"
            :deviceTypeOptions="deviceTypeOptions"
          />
          <!-- 数据展示 -->
          <a-table
            :loading="loading"
            :size="tableSize"
            rowKey="id"
            tid="1"
            :columns="columns"
            :data-source="list"
            :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
            :pagination="false"
          >
            <span slot="deviceType" slot-scope="text, record">
              {{ deviceTypeFormat(record.deviceType) }}
            </span>
            <span slot="registerTime" slot-scope="text, record" :title="parseTime(record.registerTime)">
              {{ parseTime(record.registerTime) }}
            </span>
            <span slot="installTime" slot-scope="text, record" :title="parseTime(record.installTime)">
              {{ parseTime(record.installTime) }}
            </span>
            <span slot="activation" slot-scope="text, record" :title="activationMap[record.activation]">
              {{ activationMap[record.activation] }}
            </span>
            <span slot="status" slot-scope="text, record" :title="statusFormat[record.status]">
              {{ statusFormat(record.status) }}
            </span>
            <span slot="operation" slot-scope="text, record">
              <a-divider type="vertical" v-hasPermi="['tenant:jt808:deviceInfo:edit']" />
              <a
                @click="$refs.createForm.handleUpdate(record, undefined)"
                v-hasPermi="['tenant:jt808:deviceInfo:edit']"
              >
                <a-icon type="edit" />修改
              </a>
              <!-- <a
            @click="$refs.createForm.handleUpdate(record, undefined, 'read')"
            v-hasPermi="['tenant:jt808:deviceInfo:query']"
          >
            <a-icon type="info-circle" />查看
          </a> -->
              <a-divider
                v-if="record.deviceType !== 1"
                type="vertical"
                v-hasPermi="['tenant:jt808:deviceInfo:config']"
              />
              <a
                v-if="record.deviceType !== 1"
                :style="record.online === 1 ? '' : 'color: #888;cursor: not-allowed'"
                @click="record.online === 1 && configEvent(record)"
                v-hasPermi="['tenant:jt808:deviceInfo:config']"
              >
                <a-icon type="info-circle" />参数设置</a
              >
              <a-divider type="vertical" v-hasPermi="['tenant:jt808:deviceInfo:remove']" />
              <a @click="handleDelete(record)" v-hasPermi="['tenant:jt808:deviceInfo:remove']">
                <a-icon type="delete" />删除
              </a>
            </span>
          </a-table>
          <!-- 分页 -->
          <a-pagination
            class="ant-table-pagination"
            show-size-changer
            show-quick-jumper
            :current="queryParam.pageNum"
            :total="total"
            :page-size="queryParam.pageSize"
            :showTotal="(total) => `共 ${total} 条`"
            @showSizeChange="onShowSizeChange"
            @change="changeSize"
          />
        </a-col>
      </a-row>
    </a-card>
    <!-- 上传文件 -->
    <import-excel
      ref="importExcel"
      title="终端信息管理"
      @ok="getList"
      :importTemplate="importTemplate"
      :importData="importData"
    />
    <config ref="config"></config>
  </page-header-wrapper>
</template>

<script>
import {
  listDeviceInfo,
  delDeviceInfo,
  exportDeviceInfo,
  importTemplate,
  importData,
  queryOnline,
  terminalControl
} from '@/api/jt808/deviceInfo'
import CreateForm from './modules/CreateForm'
import ImportExcel from '../components/ImportExcel'
import { exportExcel } from '@/utils/ruoyi'
import { treeselect } from '@/api/system/dept'
import config from './modules/config'
import { TreeSelect } from 'ant-design-vue'
import DeptTree from '@/views/device/vehicles/modules/DeptTree'

export default {
  name: 'DeviceInfo',
  components: {
    CreateForm,
    ImportExcel,
    config,
    ATreeSelect: TreeSelect,
    DeptTree
  },
  data() {
    return {
      list: [],
      deptOptions: [],
      selectedRowKeys: [],
      selectedRows: [],
      // 高级搜索 展开/关闭
      advanced: false,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      loading: false,
      total: 0,
      importTemplate,
      importData,
      // 最近注册时间时间范围
      daterangeRegisterTime: [],
      // 安装时间(首次注册时间)时间范围
      daterangeInstallTime: [],
      // 查询参数
      queryParam: {
        deviceId: null,
        simNo: null,
        imei: null,
        deviceModel: null,
        registerTime: null,
        installTime: null,
        deviceType: undefined,
        activation: undefined,
        virtualDevice: undefined,
        delFlag: null,
        pageNum: 1,
        pageSize: 10
      },
      columns: [
        // {
        //   title: 'ID',
        //   dataIndex: 'id',
        //   ellipsis: true,
        //   align: 'center'
        // },
        {
          title: '设备编号',
          dataIndex: 'deviceId',
          ellipsis: true,
          align: 'center'
        },
        {
          title: 'IMEI号码',
          dataIndex: 'imei',
          ellipsis: true,
          align: 'center'
        },
        {
          title: 'SIM卡号',
          dataIndex: 'simNo',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '设备类型',
          dataIndex: 'deviceType',
          scopedSlots: { customRender: 'deviceType' },
          ellipsis: true,
          align: 'center'
        },
        {
          title: '设备型号',
          dataIndex: 'deviceModel',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '最近注册时间',
          dataIndex: 'registerTime',
          scopedSlots: { customRender: 'registerTime' },
          ellipsis: true,
          align: 'center'
        },
        {
          title: '安装时间(首次注册时间)',
          dataIndex: 'installTime',
          scopedSlots: { customRender: 'installTime' },
          ellipsis: true,
          align: 'center'
        },
        {
          title: '激活状态',
          dataIndex: 'activation',
          scopedSlots: { customRender: 'activation' },
          ellipsis: true,
          align: 'center'
        },
        {
          title: '启用状态',
          dataIndex: 'activation',
          scopedSlots: { customRender: 'status' },
          ellipsis: true,
          align: 'center'
        },
        {
          title: '在线状态',
          dataIndex: 'online',
          customRender: (text, record, index) => {
            return record.online && record.online === 1 ? '在线' : '离线'
          },
          ellipsis: true,
          align: 'center'
        },
        {
          title: '操作',
          dataIndex: 'operation',
          width: '18%',
          scopedSlots: { customRender: 'operation' },
          align: 'center'
        }
      ],
      activationOptions: [
        { dictValue: '0', dictLabel: '未激活' },
        { dictValue: '1', dictLabel: '已激活' }
      ],
      activationMap: {
        0: '未激活',
        1: '已激活'
      },
      deviceTypeOptions: [],
      virtualDeviceTypeOptions: [
        { dictValue: undefined, dictLabel: '全部' },
        { dictValue: '1', dictLabel: '是' },
        { dictValue: '0', dictLabel: '否' }
      ],
      statusOptions: [],
      replaceFields: { children: 'children', title: 'label', key: 'id', value: 'id' }
    }
  },
  filters: {},
  async created() {
    await this.getDicts('iot_device_type').then((response) => {
      this.deviceTypeOptions = response.data
    })
    await this.getDicts('sys_normal_disable').then((response) => {
      this.statusOptions = response.data
    })
    this.getList()
    this.getTreeselect()
  },
  computed: {},
  watch: {},
  methods: {
    clickDeptNode(deptId) {
      this.queryParam.deptId = deptId
      this.getList()
    },
    /** 查询设备终端信息列表 */
    getList() {
      this.loading = true
      this.queryParam.params = {}
      if (
        this.daterangeRegisterTime !== null &&
        this.daterangeRegisterTime !== '' &&
        this.daterangeRegisterTime.length !== 0
      ) {
        this.queryParam.params['beginRegisterTime'] = this.daterangeRegisterTime[0]
        this.queryParam.params['endRegisterTime'] = this.daterangeRegisterTime[1]
      }
      if (
        this.daterangeInstallTime !== null &&
        this.daterangeInstallTime !== '' &&
        this.daterangeInstallTime.length !== 0
      ) {
        this.queryParam.params['beginInstallTime'] = this.daterangeInstallTime[0]
        this.queryParam.params['endInstallTime'] = this.daterangeInstallTime[1]
      }
      listDeviceInfo(this.queryParam).then((response) => {
        this.list = response.rows
        this.total = response.total
        this.loading = false
        const onlineArr = []
        for (const item of this.list) {
          onlineArr.push(item.deviceId)
        }
        clearInterval(this.queryOnlineInterval)
        this.queryOnlineInterval = null
        this.queryOnlineInterval = setInterval(() => {
          this.queryOnline(onlineArr)
        }, 6000)
      })
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParam.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.daterangeRegisterTime = []
      this.daterangeInstallTime = []
      this.queryParam = {
        deviceId: undefined,
        imei: undefined,
        deviceModel: undefined,
        activation: undefined,
        delFlag: undefined,
        virtualDevice: undefined,
        pageNum: 1,
        pageSize: 10,
        deptId: this.queryParam.deptId
      }
      this.handleQuery()
    },
    onShowSizeChange(current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize(current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map((item) => item.id)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },
    toggleAdvanced() {
      this.advanced = !this.advanced
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      var that = this
      const ids = row.id || this.ids
      this.$confirm({
        title: '确认删除所选中数据?',
        content: '当前选中编号为' + ids + '的数据',
        onOk() {
          return delDeviceInfo(ids).then(() => {
            that.onSelectChange([], [])
            that.getList()
            that.$message.success('删除成功', 3)
          })
        },
        onCancel() {}
      })
    },
    /** 导出按钮操作 */
    handleExport() {
      var that = this
      this.$confirm({
        title: '是否确认导出?',
        content: '此操作将导出当前条件下所有数据而非选中数据',
        onOk() {
          return exportDeviceInfo(that.queryParam).then((response) => {
            // that.download(response.msg)
            exportExcel(response, that.title)
            that.$message.success('导出成功', 3)
          })
        },
        onCancel() {}
      })
    },
    deviceTypeFormat(deviceType) {
      return this.selectDictLabel(this.deviceTypeOptions, deviceType)
    },
    statusFormat(status) {
      return this.selectDictLabel(this.statusOptions, status)
    },
    /** 查询部门下拉树结构 */
    getTreeselect() {
      treeselect().then((response) => {
        this.deptOptions = response.data
      })
    },
    configEvent(record) {
      this.$refs.config.open(record)
    },
    /** 终端控制-重启(复位) */
    control(record) {
      var that = this
      this.$confirm({
        title: '确认要重启该设备吗?',
        content: '当前选中设备编号为' + record.deviceId + '',
        onOk() {
          return terminalControl({ command: 4 }, record.simNo).then((response) => {
            that.getList()
            if (response.code === 200 && response.data.resultCode === 0) {
              // 成功
              that.$message.success('重启成功', 3)
            } else {
              // 失败
              that.$message.success('重启失败', 3)
            }
          })
        },
        onCancel() {}
      })
    },
    queryOnline(arr) {
      // 查询在线状态
      queryOnline(arr).then((res) => {
        res.data.forEach((element) => {
          for (const item of this.list) {
            if (element.deviceId === item.deviceId) {
              this.$set(item, 'online', element.online)
            }
          }
        })
      })
    }
  },
  destroyed() {
    clearInterval(this.queryOnlineInterval)
  }
}
</script>
